* {
  font-family: Poppins !important;
}

.font-weight-med {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

/** Auth layout container */
.auth-container {
  background: #f8f8fb !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative !important;

  .auth-container-overlay {
    background: url("./../assets/images/bg-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
  }

  .card {
    margin-top: 6rem !important;

    .card-header.with-background {
      background: #d4dbf9 !important;
      padding: 2rem !important;

      h5 {
        font-weight: bold !important;
      }

      p {
        color: #6988e9 !important;
        font-size: 0.8rem !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  form {
    padding: 2rem 0.75rem !important;

    .form-label {
      font-size: 0.75rem !important;
      font-weight: bold !important;
    }

    input {
      font-size: 0.8rem !important;
    }

    .form-check {
      color: #777 !important;
      font-size: 0.75rem !important;

      label {
        margin-top: 0.2rem !important;
      }
    }
  }
}

.alert {
  font-size: 0.8rem !important;
  margin-bottom: 1rem !important;
}

.alert-primary {
  background: #dde2fa !important;
}

.alert-secondary {
  background: #e3e4e8 !important;
}

.alert-success {
  background: #d6f3e9 !important;
}

.alert-danger {
  background: #fde1e1 !important;
}

.alert-warning {
  background: #fcf0db !important;
}

.alert-info {
  background: #dcedfc !important;
}

/** Sidebar */
.c-sidebar-nav {
  background: #556ee6 !important;

  .c-sidebar-nav-item {
    .c-sidebar-nav-link:hover {
      background: #768ae8 !important;
    }
  }
}

.c-sidebar-minimizer {
  color: #fff !important;
  background: #556ee6 !important;
}

.c-sidebar-brand {
  background: #556ee6 !important;
  padding-top: 1.35rem !important;
  padding-bottom: 1.35rem !important;
}

/** Subheader **/
.c-header {
  border-bottom: solid 1px #fff !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

  span.header-datetime-text {
    font-size: 0.8rem !important;
  }

  .c-subheader {
    border-bottom: solid 1px #fff !important;
  }
}

ol.c-nav-breadcrumb {
  padding: 1rem 1.75rem !important;
  margin-top: 0.75rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  ol.c-nav-breadcrumb {
    padding: 1rem 1rem !important;
  }
}

/** Main Content */
.content-container {
  padding: 0 !important;
  padding-bottom: 3rem !important;
}

.page-header {
  margin-bottom: 2rem !important;
}

.header-container {
  margin-bottom: 1.5rem !important;

  h2 {
    font-weight: bold !important;
  }
}

/** Bootstrap custom **/
.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.card {
  border: 0 !important;
  box-shadow: 0px 0px 17 23px black !important;
}

form {
  .form-group {
    .form-label {
      color: #777 !important;
    }

    input.form-control,
    select.form-control {
      height: 45px !important;
    }
  }
}

.nav-tabs {
  border-bottom: solid 1px #eee !important;

  .nav-link {
    font-size: 0.75rem !important;
  }

  .nav-link:hover {
    background: #fcfcfc !important;
  }

  .active {
    background: #fcfcfc !important;
    border: solid 1px #eee !important;
  }
}

.dropdown-toggle::after {
  content: none !important;
}

/** Tab Content **/
.tab-container {
  padding-top: 2rem !important;

  h4 {
    font-weight: bold !important;
    margin-bottom: 1rem !important;
  }
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-content-container {
  padding: 4rem 0;
  text-align: center;

  h5 {
    color: #777 !important;
  }

  img {
    height: 275px;
    width: 275px;
    margin-bottom: 2rem;
  }
}

button:disabled {
  cursor: not-allowed !important;
}

.loader-container {
  text-align: center;
  padding: 4rem 0;
}

/** Sweetalert **/
.swal2-container {
  .swal2-title {
    font-size: 1.75rem !important;
    font-family: "Poppins" !important;
    font-weight: bold !important;
  }

  .swal2-content {
    font-size: 0.85rem !important;
    margin-top: 0.55rem !important;
  }

  .swal2-actions {
    .swal2-cancel {
      color: #444 !important;
      background: #ddd !important;
    }

    .swal2-cancel:hover {
      color: #444 !important;
      background: #ccc !important;
    }
  }

  button {
    font-size: 0.85rem !important;
  }
}

/** Badges **/
.badge {
  font-size: 0.65rem !important;
}

.badge-danger {
  font-weight: normal !important;
  color: #f46a6a !important;
  background-color: rgba(244, 106, 106, 0.18) !important;
  padding: 0.4rem 0.6rem !important;
  border-radius: 10rem !important;
}

.badge-warning {
  font-weight: normal !important;
  color: #f1b44c !important;
  background-color: rgba(241, 180, 76, 0.18) !important;
  padding: 0.4rem 0.6rem !important;
  border-radius: 10rem !important;
}

.badge-success {
  font-weight: normal !important;
  color: #34c38f !important;
  background-color: rgba(52, 195, 143, 0.18) !important;
  padding: 0.4rem 0.6rem !important;
  border-radius: 10rem !important;
}

.badge-primary {
  font-weight: normal !important;
  color: #0c56b0 !important;
  background-color: rgba(45, 116, 204, 0.18) !important;
  padding: 0.4rem 0.6rem !important;
  border-radius: 10rem !important;
}

.badge-info {
  font-weight: normal !important;
  color: #109e93 !important;
  background-color: rgba(90, 219, 209, 0.18) !important;
  padding: 0.4rem 0.6rem !important;
  border-radius: 10rem !important;
}

/** Table **/
.table-header-container {
  width: 100% !important;
  margin-bottom: 2rem !important;

  button.search-button-action {
    background: #eee !important;
    font-size: 20px !important;
    border-color: #d8dbe0 !important;
  }
}

.datatable-search-container {
  width: 100% !important;

  input {
    padding-left: 2.5rem !important;
  }
}

.rdt_Table {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      background: #f8f9fa !important;

      * {
        font-weight: bold !important;
      }
    }
  }

  .rdt_TableRow {
    padding: 0.85rem 1rem !important;
    color: #777 !important;

    img.table-product-image {
      height: 80px;
      width: 80px;
    }

    .product-category-badge {
      color: #777 !important;
      // color: #556ee6 !important;
      // background: #e0e5fa !important;
      font-size: 11px !important;
      border-radius: 0.35rem !important;
      padding: 0.25rem 0.45rem !important;
      margin: 0.25rem !important;
    }

    .table-action-dropdown {
      .dropdown-menu {
        z-index: 9999 !important;
      }

      button {
        color: #666 !important;
        background: #eee !important;
        border: solid 1px #eee !important;
      }

      button:hover {
        border: solid 1px #ddd !important;
      }

      .dropdown-menu {
        color: #777 !important;
        font-size: 0.8rem !important;
      }
    }
  }

  .datatable_actionButtons {
    button {
      border: solid 1px #ddd !important;
      font-size: 0.75rem !important;
    }
  }
}

/** React image upload **/
.fileUploader {
  margin: 3rem 0 !important;

  .fileContainer {
    button.chooseFileButton {
      font-size: 0.8rem !important;
    }
  }
}

/** Pages **/
/** Dashboard **/
.dashboard-container .card {
  .card-body {
    h6 {
      color: #666 !important;
      font-weight: 600 !important;
    }
  }
}

/** Order detail **/
.order-details-container {
  font-size: 0.8125rem !important;

  small {
    font-size: 0.73rem !important;
  }
}

.invoice-header {
  color: #fff !important;
  background: #112652 !important;
  padding: 1rem !important;
  margin-bottom: 2rem !important;
}
